const reportData = [
  {
    reportId: "1",
    reportName: "Title 1",
    reportDescription:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Natus quasi temporibus distinctio deleniti quia amet perferendis iste non fugiat harum. ",
    link: "https://firebasestorage.googleapis.com/v0/b/talentoapp-98736.appspot.com/o/resumes_sRube577odZeLwdKNto0r96QRNu1_1716357244588%20(1).pdf?alt=media&token=ae6168f6-983e-44e3-bb16-1beef51bd726",
  },
  {
    reportId: "2",
    reportName: "Title 2",
    reportDescription:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Natus quasi temporibus distinctio deleniti quia amet perferendis iste non fugiat harum. ",
    link: "https://firebasestorage.googleapis.com/v0/b/talentoapp-98736.appspot.com/o/resumes_sRube577odZeLwdKNto0r96QRNu1_1716357244588%20(1).pdf?alt=media&token=ae6168f6-983e-44e3-bb16-1beef51bd726",
  },
  {
    reportId: "3",
    reportName: "Title 3",
    reportDescription:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Natus quasi temporibus distinctio deleniti quia amet perferendis iste non fugiat harum. ",
    link: "https://firebasestorage.googleapis.com/v0/b/talentoapp-98736.appspot.com/o/resumes_sRube577odZeLwdKNto0r96QRNu1_1716357244588%20(1).pdf?alt=media&token=ae6168f6-983e-44e3-bb16-1beef51bd726",
  },
];

export default reportData;
